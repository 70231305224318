import React, {useEffect} from "react";
import ExternalVideo from "./ExternalVideo";
import {
    ExternalVideoAppProps, ExternalVideoLayout,
    ExternalVideoProps
} from "@apps/Shared/Components/ExternalVideo/types";
import {getLocale} from "@apps/Shared/Utils/Utils";
import {useGetEmbedVideoBlockQuery} from "@apps/Shared/GraphQL/generated";
import {FeatureCheck} from "@apps/Shared/Utils/FeatureGate";

function App(props: ExternalVideoAppProps): JSX.Element {
    const [videoProps, setVideoProps] = React.useState<ExternalVideoProps>();    
    function cmsPropsToVideoProps(item: any) {
        if (item.IncludedFeature && !FeatureCheck(item.IncludedFeature))
        {
            return undefined
        }
        return {
            url: item.EmbedUrl,
            autoPlay: false,
            backgroundImageSrc: item.PreviewImage?.Url,
            title: item.Heading,
            layout: ExternalVideoLayout.Boxed
        }
    }

    const locale = getLocale();

    const {data} = useGetEmbedVideoBlockQuery({
        id: Number(props.id),
        locale: [locale]
    });

    useEffect(() => {
        if (data)
        {
            const item = data.Content?.items?.[0];
            setVideoProps(cmsPropsToVideoProps(item));
        }
    }, [data]);    

    if (videoProps) {
        return (
            <ExternalVideo {...videoProps} />
        );
    } else {
        return (
            <></>
        );
    }
}

export default App; 