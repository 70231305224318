export type ExternalVideoProps = {
    title?: string
    backgroundImageSrc?: string
    url: string
    layout: ExternalVideoLayout
    autoPlay?: boolean
}

export enum ExternalVideoLayout {
    Default = 'Default',
    Boxed = 'Boxed',
}

export enum VideoService {
    YouTube = 'YouTube',
    Vimeo = 'Vimeo',
}

export type ExternalVideoAppProps = {
    id: string
}
