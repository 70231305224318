import React from 'react'
import { ReactNode } from 'react'
import { ColorTheme } from '../../Utils'

export enum PanelType {
    Default = 'default',
    Info = 'info',
    Widget = 'widget',
    Primary = 'primary',
}

export interface IPanelProps {
    children: ReactNode
    type?: string
    className?: string
    hasError?: boolean
    compact?: boolean
    colorTheme?: string
}

const Panel = (props: IPanelProps) => {

    const theme = props.colorTheme === 'LIGHT_BG'
    ? ColorTheme.Light
    : ColorTheme.Dark
    
    return (
        <div className='panel'>
            <div
                className={`panel__content ${props.className ?? ''} panel__content ${props.type ||
                    PanelType.Default} ${props.hasError ? 'error' : ''} ${
                    props.compact ? 'compact' : ''
                } ${theme === ColorTheme.Dark ? 'panel__content--dark' : ''}`}
            >
                {props.children}
            </div>
        </div>
    )
}

export default Panel