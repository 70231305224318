import React from 'react';
import { createRoot } from 'react-dom/client';
import ExternalVideoApp from "./ExternalVideoApp";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const videoBlocks = document.querySelectorAll('.js-external-video');
const queryClient = new QueryClient();

if (videoBlocks.length) {
    videoBlocks.forEach(hiddenInput => {
        const id = (hiddenInput as HTMLInputElement).value;
        const container = document.getElementById(`js-external-video-${id}`);
        if (container) {
            const root = createRoot(container);
            root.render(
                <QueryClientProvider client={queryClient}>
                    <ExternalVideoApp id={id}/>
                </QueryClientProvider>                
            );
        }
    });
}