import {GetCustomerSessionContext} from "@apps/Shared/Utils/ContextHelper";
import forEach from "lodash/forEach";

function FeatureExists(features: string[], feature: string | string[]) {
    if (feature === '' || feature === null) {
        return true
    }

    let exists = false
    //Feature is array, check all
    if (Array.isArray(feature)) {
        forEach(feature, feat => {
            if (features.includes(feat)) {
                exists = true
                return false //break foreach
            }
            return
        })
    } else {
        //Else, check as usual (string)
        exists = features.includes(feature)
    }
    return exists
}

export function FeatureCheck(includedFeature: string | string[]) {
    const customerContext = GetCustomerSessionContext();
    const activeCustomer = customerContext && customerContext.ActiveCustomer;

    // If the component has no feature
    if (!includedFeature) {
        return true;
    }

    if (activeCustomer) {
        return FeatureExists(activeCustomer.features || [], includedFeature);
    }
}